import Adapter from '@/adapters/Adapter'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/storage'

import util from '@/lib/util'

import firebaseConverters from './firebaseConverters'

const _ = require('lodash')

class FirebaseAdapter extends Adapter {
  constructor (config) {
    super(config)

    firebase.initializeApp(config)

    this.config = config
    this.db = firebase.firestore()
    this.functions = firebase.functions()

    this.auth = firebase.auth()
    this.googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    this.microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com')

    if (process.env.NODE_ENV === 'development') {
      console.log('Connecting to local emulators, authentication and functions.')
      this.functions.useEmulator("localhost", 5001)
      // this.db.useEmulator("localhost", 8080)
      // this.auth.useEmulator("http://localhost:9099")
    }
  }

  // ============================== AUTHENTICATION ==============================

  registerAuthCallback (onLoggedIn, onNotLoggedIn) {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        onLoggedIn(user)
      } else {
        onNotLoggedIn()
      }
    })

    this.auth
      .getRedirectResult()
      .then(result => {
        _.noop(result)
        console.debug('Successful redirect.')
      }).catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        // Adds visibility to auth errors.
        console.error('REDIRECT ERROR', errorCode, errorMessage)
      });
  }

  authenticate ({ provider }) {
    if (provider === 'google') {
      this.auth.signInWithRedirect(this.googleAuthProvider)
    } else if (provider === 'microsoft') {
      this.auth.signInWithRedirect(this.microsoftAuthProvider)
    } else {
      // Attempt Google as the fallback. In the future, perhaps email?
      this.auth.signInWithRedirect(this.googleAuthProvider)
    }
  }

  deauthenticate () {
    return this.auth.signOut()
  }

  // ======================================== USER ========================================

  getCurrentUser () {
    return this.auth.currentUser
  }

  updateUser (data) {
    // TODO Put some constraints on the data that can be updated here.
    const user = this.getCurrentUser()

    if (_.isNil(user)) {
      return new Promise((resolve, reject) => {
        reject('User has not been loaded yet or is not available.')
      })
    }

    const uid = user.uid
    const userRef = this.db.collection('data').doc(uid)

    // Create the document if it doesn't already exist.
    return userRef.set(data, { merge: true })
  }

  // ======================================== CONTENT LISTENERS ========================================

  registerContentsListener (onUpdate) {
    const contentsRef = this._getCollection('contents')
    return contentsRef
      .withConverter(firebaseConverters.ContentConverter)
      .where('archived', '==', false)
      .where('trashed', '==', false)
      .onSnapshot(snapshot => {
        const contents = []

        snapshot.forEach(doc => {
          contents.push(doc.data())
        })

        onUpdate(contents)
      })
  }

  registerTrashListener (onUpdate) {
    return this._getCollection('contents')
      .withConverter(firebaseConverters.ContentConverter)
      .where('trashed', '==', true)
      .onSnapshot(snapshot => {
        const items = []

        snapshot.forEach(doc => {
          items.push(doc.data())
        })

        onUpdate(items)
      })
  }

  registerArchiveListener (onUpdate) {
    return this._getCollection('contents')
      .withConverter(firebaseConverters.ContentConverter)
      .where('archived', '==', true)
      .where('trashed', '==', false)
      .onSnapshot(snapshot => {
        const items = []

        snapshot.forEach(doc => {
          items.push(doc.data())
        })

        onUpdate(items)
      })
  }

  registerUserStateListener (onUpdate) {
    const user = this.getCurrentUser()
    if (_.isNil(user)) {
      console.error('Attempted to subscribe to user data but did not have a user to listen to!')
      return
    }

    const userRef = this.db.collection('data').doc(user.uid)

    return userRef.onSnapshot(snapshot => {
      const userData = snapshot.data()
      onUpdate(userData)
    })
  }

  // ======================================== CONTENT QUERIES ========================================

  _getCollection (key) {
    const user = this.getCurrentUser()

    if (_.isNil(user)) {
      return new Promise((resolve, reject) => {
        reject('User has not been loaded yet or is not available.')
      })
    }

    return this.db.collection('data').doc(user.uid).collection(key)
  }

  // ============================== CONTENT + DOCUMENT CRUD OPERATIONS ==============================

  // Used to acquire a new ID for table-of-contents creation.
  provisionNewContentReference () {
    return new Promise(resolve => {
      const contentRef = this._getCollection('contents').doc()
      resolve(contentRef)
    })
  }

  // Used to acquire a new ID for document creation.
  provisionNewDocumentReference (id = null) {
    return new Promise(resolve => {
      let documentRef
      if (_.isString(id)) {
        documentRef = this._getCollection('documents').doc(id)
      } else {
        documentRef = this._getCollection('documents').doc()
      }
      resolve(documentRef)
    }) 
  }

  createContent (content, parent, batch_ = null) {
    const batch = batch_ ? batch_ : this.db.batch()

    const newContentRef = this._getCollection('contents').doc(content.id)
    const newContentData = firebaseConverters.ContentConverter.toFirestore(content)
    batch.set(newContentRef, newContentData)

    this.updateContent(parent, batch)

    if (_.isNil(batch_)) {
      return batch.commit().then(() => {
        return content
      })
    }
  }

  updateContent (content, batch_ = null) {
    const items = _.isArray(content) ? _.filter(content) : _.filter([content])

    const batch = batch_ ? batch_ : this.db.batch()

    _.forEach(items, item => {
      if (_.isNil(item.id)) { return }

      const itemRef = this._getCollection('contents').doc(item.id)
      const itemData = firebaseConverters.ContentConverter.toFirestore(item)

      batch.update(itemRef, itemData)
    })

    // If this method doesn't receive a batch object, it's likely being called to
    // engage the update process explicitly. So go ahead and update.
    if (_.isNil(batch_)) {
      if (_.isEmpty(items)) {
        return util.errorPromise('No content to update.')
      }
      return batch.commit()
    }
  }

  createDocument (document, parent) {
    const batch = this.db.batch()

    this.createContent(document.content, parent)

    const newDocumentRef = this._getCollection('documents').doc(document.id)
    const newDocumentData = firebaseConverters.DocumentConverter.toFirestore(document)
    batch.set(newDocumentRef, newDocumentData)

    return batch.commit().then(() => {
      return document
    })
  }

  updateDocument (document) {
    const batch = this.db.batch()

    const documentData = firebaseConverters.DocumentConverter.toFirestore(document)
    const documentRef = this._getCollection('documents').doc(document.id)
    batch.update(documentRef, documentData)

    this.updateContent(document.content, batch)
    this.updateTagSnippets(document.content, batch)

    return batch.commit()
  }

  loadDocument (documentKey) {
    const documentRef = this._getCollection('documents').doc(documentKey)

    return documentRef.withConverter(firebaseConverters.DocumentConverter)
      .get()
      .then(doc => {
        return doc.exists ? doc.data() : null
      })
  }

  publishDocument (document, slug) {
    const args = {
      documentId: document.id,
      slug
    }

    const publish = this.functions.httpsCallable('publishDocument')
    return publish(args)
  }

  delete (items) {
    if (_.isEmpty(items)) { return new Promise(resolve => {
        resolve()
      })
    }

    const batch = this.db.batch()

    _.forEach(items, item => {
      if (item.operation === 'DELETE') {
        const contentRef = this._getCollection('contents').doc(item.content.id)
        batch.delete(contentRef)
  
        if (item.content.isDocument) {
          const documentRef = this._getCollection('documents').doc(item.content.key)
          batch.delete(documentRef)
        }
      } else if (item.operation === 'UPDATE') {
        const itemData = firebaseConverters.ContentConverter.toFirestore(item.content)
        const contentRef = this._getCollection('contents').doc(item.content.id)
        batch.update(contentRef, itemData)
      }
    })

    return batch.commit()
  }

  loadTagSnippets (hashtag) {
    // TODO Sanitize the hashtag.
    const tagRef = this._getCollection('tags').doc(hashtag)
    return tagRef.get().then(doc => doc.exists ? doc.data() : [])
  }

  updateTagSnippets (content, batch_ = null) {
    if (_.isNil(content.snippets)) { return }

    const batch = batch_ ? batch_ : this.db.batch()

    _.forEach(content.snippets, (snippets, hashtag) => {
      const tr = {}
      tr[content.id] = JSON.stringify(snippets)

      const tagRef = this._getCollection('tags').doc(hashtag)
      batch.set(tagRef, tr, { merge: true })
    })

    if (_.isNil(batch_)) {
      return batch.commit()
    }
  }

  uploadFileForDocument (file, document) {
    // https://firebase.google.com/docs/storage/web/upload-files
    // https://dev.to/suraj975/ckeditor-image-upload-with-firebase-and-react-1pe8

    const storage = firebase.storage().ref()
    const uid = this.getCurrentUser().uid
    const path = `uploads/${uid}/${document.id}/${file.name}`
    const uploadTask = storage
      .child(path)
      .put(file) // metadata

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'

        snapshot => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          console.info("Upload is " + progress + "% done")

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.info("Upload is paused")
              break

            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.info("Upload is running")
              break
          }
        },

        error => {
          switch (error.code) {
            case "storage/unauthorized":
              reject("You don't have permission to access this object.")
              break

            case "storage/canceled":
              reject("Upload was cancelled.")
              break

            case "storage/unknown":
              reject("Unknown error occurred, inspect error.serverResponse")
              break
          }
        },

        () => {
          // Upload completed successfully, now we can get the download URL
          // TODO Register the upload in Firestore for reference?
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(resolve)
        }
      ) // end uploadTask.on

    }) // end Promise
  } // end uploadFileForDocument

  toggleTodo (contentId, originalTodo) {
    const args = {
      contentId,
      originalTodo
    }

    const toggleTodo = this.functions.httpsCallable('toggleTodo')
    return toggleTodo(args)
  }
}

export default FirebaseAdapter
