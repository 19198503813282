import Document from '@/models/Document'
import Content from '@/models/Content'
import { DateTime } from 'luxon'

const DocumentConverter = {
  toFirestore: document => {
    const data = {
      title: document.title,
      content: document.body,
      created: document.created ? (document.created.isLuxonDateTime ? document.created.toJSDate() : document.created) : new Date(),
      updated: document.updated ? document.updated.isLuxonDateTime ? document.updated.toJSDate() : document.updated : new Date(),
      fullwidth: document.fullwidth,
    }

    return data
  },

  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)

    // Dates from Firestore are a Timestamp instance. Convert this to a JS date then wrap
    // in a Luxon DateTime for use in the app.
    // https://firebase.google.com/docs/reference/android/com/google/firebase/Timestamp
    data.created = data.created ? DateTime.fromJSDate(data.created.toDate()) : DateTime.now()
    data.updated = data.updated ? DateTime.fromJSDate(data.updated.toDate()) : DateTime.now()

    return new Document.Document(snapshot.id, data)
  }
}

const ContentConverter = {
  toFirestore: content => {
    const data = {
      title: content.title,
      type: content.type,
      key: content.key,
      parent: content.parent,
      trashed: content.trashed,
      starred: content.starred,
      created: content.created ? (content.created.isLuxonDateTime ? content.created.toJSDate() : content.created) : new Date(),
      updated: content.updated ? (content.updated.isLuxonDateTime ? content.updated.toJSDate() : content.updated) : new Date(),
      tags: content.tags,
      archived: content.archived,
      todos: content.todos
    }

    if (content.isFolder) {
      data.children = content.children || []
    }

    return data
  },

  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)

    // Dates from Firestore are a Timestamp instance. Convert this to a JS date then wrap
    // in a Luxon DateTime for use in the app.
    // https://firebase.google.com/docs/reference/android/com/google/firebase/Timestamp
    data.created = data.created ? DateTime.fromJSDate(data.created.toDate()) : DateTime.now()
    data.updated = data.updated ? DateTime.fromJSDate(data.updated.toDate()) : DateTime.now()

    const content = new Content.Content(snapshot.id, data)
    return content
  }
}

export default {
  DocumentConverter,
  ContentConverter
}
