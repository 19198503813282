<template>
  <div class="todos" v-if="isLoggedIn">
    <Sidebar />

    <div class="body">
      <div class="header">
        <h1>Todos</h1>
      </div>

      <div class="ck-content contents">
        <div v-for="contentWithTodos in todosMap" v-bind:key="contentWithTodos.content.id">
          <div v-if="contentWithTodos.hasUnchecked">
            <content-link v-bind:content="contentWithTodos.content" />

            <ul class="todo-list">
              <li v-for="todo in contentWithTodos.uncheckedTodos" v-bind:key="todo.id" class="todo">
                <input type="checkbox" @click="toggleTodo(contentWithTodos.content.id, todo)" />
                <span v-html="todo.description" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="loading-wrapper" v-else>
    <loading />
  </div>
</template>

<style lang="scss" scoped>
.todos {
  position: relative;
  display: flex;
  height: calc(100% - 36px);
}

.body {
  height: 100%;
  width: 82%;
}
// Responsiveness for sidebar.
@media (max-width:1160px) {
  .body {
    width: 100%;
  }
}

.header {
  height: 32px;
  padding: 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
h1 {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 10px;
  height: 37px;
}
h2 {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 10px;
  height: 37px;
}
.contents {
  overflow-y: scroll;
  height: calc(100% - 65px);
  padding-top: 12px;
}

ul.todo-list {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 15px;

  li.todo {
    list-style-type: none;
    margin-bottom: 5px;
  }

  input[type=checkbox] {
    margin-right: 10px;
    height: 16px;
    width: 16px;
    cursor: pointer;

    transition: box-shadow .25s ease-in-out,background .25s ease-in-out,border .25s ease-in-out,-webkit-box-shadow .25s ease-in-out;
    &:hover {
      box-shadow: 0 0 0 5px rgba(0,0,0,.1);
    }
  }
}
</style>

<script>
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'
import ContentLink from '@/components/ContentLink'
import util from '@/lib/util'

import { mapState, mapGetters } from 'vuex'

const _ = require('lodash')

class Todo {
  constructor (todoHtml) {
    this.todoHtml = todoHtml

    this.elt = document.createElement('div')
    this.elt.innerHTML = todoHtml

    this.description = this.elt.getElementsByClassName('todo-list__label__description')[0].innerHTML

    this.isChecked = _.includes(this.todoHtml, 'checked="checked"')

    this.id = util.generateId()
  }
}

export default {
  name: 'Todos',

  components: {
    Sidebar,
    Loading,
    ContentLink
  },

  mounted () {
    document.title = `Doctoral | Todos`
  },

  computed: {
    ...mapState(['contents']),
    ...mapGetters(['isLoggedIn']),

    // Create a map from content id to array of Todo instances.
    todosMap () {
      let tr = []

      for (const content of this.contents) {
        const todos = _.map(content.todos, todo => new Todo(todo))
        const uncheckedTodos = _.filter(todos, todo => !todo.isChecked)
        const hasUnchecked = _.size(uncheckedTodos) > 0
        tr.push({
          content, 
          todos,
          uncheckedTodos,
          hasUnchecked
        })
      }

      tr.sort((a, b) => {
        if (this.startsWithNumber(a.content.title) && this.startsWithNumber(b.content.title)) {
          return a.content.title > b.content.title ? 1 : -1
        } else if (this.startsWithNumber(a.content.title)) {
          return 1
        } else if (this.startsWithNumber(b.content.title)) {
          return -1
        } else {
          return _.toLower(a.content.title) > _.toLower(b.content.title) ? 1 : -1
        }
      })

      return tr
    }
  }, // computed

  methods: {
    startsWithNumber (str) {
      return _.includes('1234567890', _.first(str))
    },

    toggleTodo (contentId, todo) {
      console.debug('Toggling todo from Todos View', todo)
      this.$store.dispatch('toggleTodo', {
        contentId,
        originalTodo: todo.todoHtml
      })
    }
  } // methods
}
</script>
